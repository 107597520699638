import React from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import { setReturnURL } from "../utils/login"

import { isLoggedIn } from "../api/auth"
import AuthPage from "../components/auth-page"
import GuestbookPage from "../components/guestbook-page"
import NotFoundPage from "./404"

const isBrowser = typeof window !== `undefined`

const NavigateLogin = ({ path }) => {
  setReturnURL(path || null)
  if (isBrowser) navigate(`/login/`, { replace: true })
  return null
}

const PrivateRouteFallback = () =>
  isLoggedIn() ? <NotFoundPage /> : <NavigateLogin />

const PrivateRoute = ({ component: Component, ...rest }) =>
  isLoggedIn() ? <Component {...rest} /> : <NavigateLogin {...rest} />

const SitePage = () => (
  <Router>
    <PrivateRoute
      pageId="toc"
      path="/site/toc/"
      component={AuthPage}
      title="Table of contents"
    />
    <PrivateRoute
      pageId="prolog"
      path="/site/prolog/"
      component={AuthPage}
      title="Prolog"
    />
    <PrivateRoute
      pageId="chapters"
      path="/site/chapters/"
      component={AuthPage}
      title="Chapters"
    />
    <PrivateRoute
      pageId="guestbook"
      path="/site/guestbook/"
      component={GuestbookPage}
      title="Guestbook"
    />
    <PrivateRoute
      pageId="epilog"
      path="/site/epilog/"
      component={AuthPage}
      title="Epilog"
    />
    <PrivateRouteFallback default />
  </Router>
)

export default SitePage
