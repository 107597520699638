import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./guestbook-page.scss"

const GuestbookPage = () => (
  <Layout>
    <SEO lang="en" title="Guestbook" />
    <section className="guestbook-page--container">
      <h1 className="fg-black-5">Guestbook</h1>
    </section>
  </Layout>
)

export default GuestbookPage
