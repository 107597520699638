import React from "react"
import PropTypes from "prop-types"

import "./time-table.scss"

const TimeTable = ({ children }) => (
  <table className="time-table">
    <thead>
      <tr>
        <th className="fg-black-9 time-table--time-column">Time</th>
        <th className="fg-black-9 time-table--activity-column">Activity</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
)

TimeTable.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TimeTable
