import React from "react"
import PropTypes from "prop-types"

const TimeTableEntry = ({ time, children }) => (
  <tr className="time-table-entry">
    <th className="fg-black-6 time-table--time-column">{time}</th>
    <td className="fg-black-3 time-table--activity-column">{children}</td>
  </tr>
)

TimeTableEntry.propTypes = {
  time: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TimeTableEntry
