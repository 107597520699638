import React from "react"
import PropTypes from "prop-types"

const YoutubeVideo = ({ title, code }) => (
  <div style={{ textAlign: `center` }}>
    <iframe
      title={title}
      width={560}
      height={315}
      src={`https://www.youtube.com/embed/${code}`}
      frameBorder={0}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
    />
  </div>
)

YoutubeVideo.propTypes = {
  title: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

export default YoutubeVideo
